import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Paper as MuiPaper,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import dashboardItems from "../components/sidebar/dashboardItems";
import sftpDashboardItems from "../components/sidebar/sftpDashboardItems";
import { useSelector } from "react-redux";
import "../index.css";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/firmWayLogo.png";
import logo2 from "../assets/images/FirmwayWhiteLogo.svg";
import SidebarNav from "components/sidebar/SidebarNav";
import GlobalStyle from "components/GlobalStyle";

const Navbar = React.lazy(() => import("../components/navbar/Navbar"));
const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `60px`,
  [theme.breakpoints.up("sm")]: {
    width: `60px`,
  },
});

const Root = styled.div`
  display: flex;
  min-height: 100%;
`;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { isMiniSidebar } = useSelector((state) => state.trackreconciliation);
  const { sftp } = useSelector((state) => state.sftp);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Box className="zoom-ninety">
        <Drawer variant="permanent" open={!isMiniSidebar}>
          <Brand
            style={{ background: "#233044", height: "50px" }}
            component={NavLink}
            to="/requeststatement"
          >
            <Box marginTop={"38px"}>
              <img
                src={isMiniSidebar ? logo : logo2}
                alt="Logo"
                height={isMiniSidebar ? "34px" : "auto"}
                width={isMiniSidebar ? "38px" : "200px"}
                style={{ zoom: "98%" }}
              />
            </Box>
          </Brand>
          <Box style={{ background: "#233044", height: "50px" }}></Box>
          <SidebarNav items={sftp ? sftpDashboardItems : dashboardItems} />
        </Drawer>
      </Box>

      <AppContent>
        <React.Suspense fallback="Loading...">
          <Box className="zoom-ninety">
            <Navbar
              onDrawerToggle={handleDrawerToggle}
              className="zoom-ninety"
            />
          </Box>
        </React.Suspense>
        <MainContent
          style={{
            paddingInline: "12px",
            paddingTop: "50px",
            paddingBottom: "12px",
          }}
        >
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
