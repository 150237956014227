import React, { useState, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Stack,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import {
  CloseIcon,
  QuestionMark,
  FileAttachment,
  RedCloseIcon,
} from "components/AllSvg";
import "assets/styles/reviewdone.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addRemarkforActionTracker,
  setActionViewTrackerSelected,
} from "store/requeststatement/slice";
import makeStyles from "@mui/styles/makeStyles";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import DummyButton from "components/button/DummyButton";

const style = {
  width: "30vw",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffffff",
  padding: "25px",
  margin: "30px",
  boxShadow: "0px 4px 15px 0px #00000040",
};

const submit = {
  width: "100px",
  height: "30px",
  fontSize: "12px",
  fontWeight: 700,
  background: "#005724",
  borderRadius: "5px",
  color: "#ffffff",
};

const radio = {
  color: "#000000",
  "& .MuiSvgIcon-root": {
    fontSize: 14,
  },
  "&.Mui-checked": {
    color: "#005724",
  },
};
const ActionTrackerAddRemarkModal = (props) => {
  const dispatch = useDispatch();
  const {
    viewActionTrackerData,
    actionViewTrackerSelected,
    uncheckedDataIds,
    recoId,
  } = useSelector((state) => state.trackreconciliation);

  const [radioType, setRadioType] = useState("internal");
  const [info, setInfo] = useState(false);
  const [firstFile, setFirstFile] = useState([]);
  const [secondFile, setSecondFile] = useState([]);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);

  const [backenderrormsg, setBackendErrormsg] = useState(false);
  const [backendsuccessmsg, setBackendSuccessmsg] = useState(false);
  const [showbackenderrormsg, setShowBackendErrormsg] = useState("");
  const [showbackendSuccessmsg, setShowBackendSuccessmsg] = useState("");
  const [disabled, setDisabled] = useState(false);

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
    cookieAlert: {
      "& .MuiAlert-icon": {
        color: "#ffffff",
      },
    },
  }));

  const classes = useStyles();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      remark: "",
    },
    validationSchema: Yup.object().shape({
      remark: Yup.string().required("Add remark"),
    }),

    onSubmit: (val) => {
      setDisabled(true);
      let result = [];
      viewActionTrackerData?.actionTrackerRecords?.forEach((option1, index) => {
        actionViewTrackerSelected?.forEach((option2, index) => {
          if (option1.dataId === option2) {
            var obj = {
              recoId: option1.recoId,
              partyId: option1.partyId,
              dataId: option1.dataId,
            };
            result.push(obj);
          }
        });
      });

      const formData = new FormData();
      [firstFile[0], secondFile[0]].forEach((file) => {
        formData.append("statements", file);
      });

      formData.append(
        "addremark",
        JSON.stringify({
          ...val,
          dataIds: result,
          uncheckedDataIds,
          selectAll: props.selectAll,
          recoId: recoId,
          internal: radioType === "internal" ? true : false,
        })
      );

      dispatch(addRemarkforActionTracker({ data: formData })).then((res) => {
        if (res?.payload?.success === false) {
          setBackendErrormsg(true);
          setShowBackendErrormsg(res?.payload?.message);
        } else {
          setDisabled(false);
          props.setIsItemSelected(false);
          setBackendSuccessmsg(true);
          setShowBackendSuccessmsg(res?.payload?.message);
        }
        props.setRefresh(!props.refresh);
        handleClose();
        dispatch(setActionViewTrackerSelected([]));
        props.setIsAllSelected(false);
      });
    },
  });

  const { getInputProps, open } = useDropzone({
    noKeyboard: true,
    multiple: false,
    minSize: 0,
    maxSize: 5242880,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
      "application/doc": [],
      "application/pdf": [],
      "application/docx": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        setIsFileTooLarge(false);
        if (firstFile.length === 0) {
          setFirstFile(acceptedFiles);
          return;
        }
        if (secondFile.length === 0) {
          setSecondFile(acceptedFiles);
          return;
        }
      },
      [firstFile, secondFile]
    ),
    onDropRejected: useCallback((fileRejections) => {
      setIsFileTooLarge(true);
    }, []),
  });

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  //cancel click
  const handleClose = () => {
    props.setopenActionTrackerAddRemark(false);
    setFirstFile([]);
    setSecondFile([]);
  };

  return (
    <>
      <Modal
        open={props.openActionTrackerAddRemark}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            {/* <Box sx={form}> */}
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
            >
              <Typography className="popuptitle">Add Remark</Typography>
              <IconButton onClick={handleClose} disableRipple>
                <CloseIcon />
              </IconButton>
            </Stack>

            {/* Radio Buttons */}
            <Stack sx={{ position: "absolute" }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event) => setRadioType(event.target.value)}
                  defaultValue="internal"
                  onMouseEnter={() => setInfo(true)}
                  onMouseLeave={() => setInfo(false)}
                >
                  <FormControlLabel
                    value="internal"
                    control={<Radio sx={radio} disableRipple />}
                    label={
                      <Typography
                        fontSize="12px"
                        fontFamily={
                          radioType === "internal"
                            ? "fontsfree"
                            : "SFProdisplay"
                        }
                      >
                        Internal Comment
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="external"
                    control={<Radio sx={radio} disableRipple />}
                    label={
                      <Typography
                        fontSize="12px"
                        fontFamily={
                          radioType === "external"
                            ? "fontsfree"
                            : "SFProdisplay"
                        }
                      >
                        External Comment
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {info && (
                <Box
                  zIndex={100}
                  width="100%"
                  bgcolor="#005724"
                  borderRadius={4}
                  padding="16px"
                  position="relative"
                  top={0}
                  left={0}
                  onMouseEnter={() => setInfo(true)}
                  onMouseLeave={() => setInfo(false)}
                >
                  <Stack direction="row">
                    <QuestionMark />
                    <Stack gap={1} marginX={2}>
                      <Typography
                        color="#fff"
                        fontSize="14px"
                        fontFamily="fontsfree"
                      >
                        What's this?
                      </Typography>
                      <Typography
                        color="#FFF"
                        fontSize="13px"
                        fontFamily="SFProdisplay"
                      >
                        Internal comments will be displayed only to the company.
                        External comments will be displayed to both Company and
                        Party.
                      </Typography>
                    </Stack>
                    <Box
                      sx={{ cursor: "pointer" }}
                      // onClick={() => dispatch(setGotIt(false))}
                    >
                      <Typography
                        color="#ff9a01"
                        fontSize="11px"
                        fontFamily="fontsfree"
                      >
                        Got&nbsp;It
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Stack>
            <br />

            {/* action required checkbox */}
            <Box
              display="flex"
              justifyContent="end"
              mt={"10px"}
              mb={"-1px"}
              visibility={"hidden"}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id="actionRequired"
                    name="actionRequired"
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#005724",
                      },
                    }}
                    // checked={checked}
                    onChange={(event) => {
                      setFieldValue("actionRequired", event.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                fontFamily={"SFProdisplay"}
                fontWeight={700}
                fontSize="14px"
                label="Action Required"
              />
            </Box>

            {/* remark box */}
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ marginBottom: "10px" }}>Remarks</Typography>
              <span className="text-danger">*</span>
            </Box>
            <TextField
              id="remark"
              name="remark"
              variant="outlined"
              size="small"
              autoComplete="off"
              multiline
              rows={2}
              sx={{ marginBottom: "10px" }}
              onChange={(event) => {
                setFieldValue("remark", event.target.value);
              }}
              fullWidth
            />
            <br />
            {errors.remark && touched.remark ? (
              <span className="text-danger">{errors.remark}</span>
            ) : null}

            <Box>
              <Box
                p={2}
                mt={2}
                sx={{
                  border: "1px solid #BBBBBB",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
                // {...getRootProps()}
              >
                <input {...getInputProps()} />
                <FileAttachment></FileAttachment>
                <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                  {/* {selectedFiles.length === 0?'': selectedFiles[0]?.name} */}
                  {firstFile.length === 0 ? "" : firstFile[0]?.name}
                </Typography>
                {firstFile.length === 0 ? (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={open}
                    style={{
                      marginLeft: "auto",
                      backgroundColor: "#ececec",
                      color: "#005724",
                      fontWeight: 700,
                      fontFamily: "fontsfree",
                    }}
                  >
                    Browse&nbsp;File
                  </Button>
                ) : (
                  <Box style={{ marginLeft: "auto" }}>
                    <RedCloseIcon
                      width="15"
                      height="35"
                      cursor="pointer"
                      onClick={() => setFirstFile([])}
                    />
                  </Box>
                )}
              </Box>
              <Stack direction="row" mt="5px">
                <Typography
                  mr="auto"
                  fontFamily="SFProdisplay"
                  color="#a7a7a7"
                  fontSize="10px"
                  fontWeight={400}
                >
                  Acceptable file types : pdf, xls, xlsx, xlsm, csv, doc, docx,
                  jpg, png
                </Typography>
                <Typography
                  fontFamily="SFProdisplay"
                  color="#a7a7a7"
                  fontSize="10px"
                  fontWeight={400}
                >
                  File size : 5 MB
                </Typography>
              </Stack>

              {isFileTooLarge && (
                <div style={{ color: "red" }}>File is too large.</div>
              )}

              {secondFile.length === 1 && (
                <Box
                  p={2}
                  sx={{
                    border: "1px solid #BBBBBB",
                    height: "40px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                  // {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FileAttachment></FileAttachment>

                  <Typography ml={4} fontWeight={700} fontFamily="fontsfree">
                    {secondFile.length === 0 ? "" : secondFile[0]?.name}
                  </Typography>
                  <Box style={{ marginLeft: "auto" }}>
                    <RedCloseIcon
                      width="15"
                      height="35"
                      cursor="pointer"
                      onClick={() => setSecondFile([])}
                    />
                  </Box>
                </Box>
              )}

              {firstFile.length === 0 || secondFile.length === 1 ? (
                <Box marginY="30px"></Box>
              ) : (
                <Typography
                  onClick={open}
                  marginY="8px"
                  ml="auto"
                  color="#005724"
                  fontWeight={700}
                  fontSize="12px"
                  fontFamily="fontsfree"
                  sx={{
                    textDecoration: "underline",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  + Add more
                </Typography>
              )}
            </Box>

            <Stack
              spacing={2}
              direction="row"
              marginTop={2}
              justifyContent="flex-end"
            >
              <Button className="cancel" onClick={handleClose}>
                Cancel
              </Button>
              {disabled ? (
                <DummyButton />
              ) : (
                <Button style={submit} type="submit">
                  Submit
                </Button>
              )}
            </Stack>
            {/* </Box> */}
          </form>
        </Box>
      </Modal>

      {/* for Success */}
      <Snackbar
        open={backendsuccessmsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendSuccessmsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendSuccessmsg(false);
          }}
          severity="success"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "rgba(37, 172, 75, 1)",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackendSuccessmsg}
        </Alert>
      </Snackbar>
      {/* for error message */}
      <Snackbar
        open={backenderrormsg}
        autoHideDuration={2000}
        onClose={() => {
          setBackendErrormsg(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setBackendErrormsg(false);
          }}
          severity="error"
          className={classes.cookieAlert}
          sx={{
            width: "100%",
            bgcolor: "#FF0000",
            color: "white",
            boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.4)",
            fontWeight: "700",
          }}
        >
          {showbackenderrormsg}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ActionTrackerAddRemarkModal;
