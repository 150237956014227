import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import state from "./state";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const uploadLedger = createAsyncThunk(
  "uploadLedger",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        // `/partyconfmn/api/v1/recorecords/party/confirmation/map`,
        `/partyconfmn/api/v1/recorecords/party/confirmation/ledger/map`,
        formData,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateData = createAsyncThunk(
  "validateData",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        // `/reconinit/api/v1/recorecords/party/confirmation/validate`,
        `/partyconfmn/api/v1/recorecords/party/confirmation/validate`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateDataOpenItem = createAsyncThunk(
  "validateDataOpenItem",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        // `/reconinit/api/v1/recorecords/party/confirmation/validate`,
        `/partyconfmn/api/v1/recorecords/party/confirmation/validate`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPartyData = createAsyncThunk(
  "getPartyData",
  async ({ token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/partyconfmn/api/v2/recorecords/party/adv-confirmation`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const attachDocument = createAsyncThunk(
  "attachDocument",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/supporting-data`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadStatement = createAsyncThunk(
  "loadStatement",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `partyconfmn/api/v1/recorecords/party/load-statement`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendCode = createAsyncThunk(
  "sendCode",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/verification-code`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyCode = createAsyncThunk(
  "verifyCode",
  async ({ token, code, confirmerId }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/verify-code?confirmerId=${confirmerId}&verificationCode=${code}`,
        null,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// remove party statement file
export const removeUploadedFile = createAsyncThunk(
  "removeUploadedFile",
  async ({ statementId, token, requestType }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/remove?statementId=${statementId}`,
        null,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );

      const response = { ...res.data, requestType };
      return response;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Yes And I'm In Button API CAll
export const actionRequest = createAsyncThunk(
  "actionRequest",
  async ({ actionType, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/lead-request?action=${actionType}`,
        null,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get summary details
export const getConfirmationSummary = createAsyncThunk(
  "getConfirmationSummary",
  async ({ recoId, statementId, token, requestType }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `partyconfmn/api/v1/statement/mappedsummary/by-statement?recoId=${recoId}&statementId=${statementId}`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      const response = { ...res.data, requestType };
      return response;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//upload Open Item file
export const uploadOpenItem = createAsyncThunk(
  "uploadOpenItem",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/openitem/map`,
        formData,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBlankConfirmationData = createAsyncThunk(
  "getBlankConfirmationData",
  async ({ token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/partyconfmn/api/v2/recorecords/party/confirmation`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Send Verification Code
export const sendVerificationCode = createAsyncThunk(
  "sendVerificationCode",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        "/reconinit/api/v1/recorecords/tracking/signoff/verification-code",
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Party Send Verification Code
export const verifyOTP = createAsyncThunk(
  "verifyOTP",
  async ({ token, confirmerId, verificationCode }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/reconinit/api/v1/recorecords/tracking/signoff/verify-code?confirmerId=${confirmerId}&verificationCode=${verificationCode}`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const putBlankConfirmation = createAsyncThunk(
  "putBlankConfirmation",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v2/recorecords/party/confirm`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const putSkipMapping = createAsyncThunk(
  "putSkipMapping",
  async ({ data, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/skipmapping`,
        data,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendPartyUserData = createAsyncThunk(
  "sendPartyUserData",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v2/recorecords/party/confirm`,
        formData,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadLedgerDocument = createAsyncThunk(
  "uploadLedgerDocument",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        // `/partyconfmn/api/v1/recorecords/party/confirmation/map`,
        `/partyconfmn/api/v2/recorecords/party/confirmation/ledger/nonexcel-statement`,
        formData,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadOpenItemDocument = createAsyncThunk(
  "uploadOpenItemDocument",
  async ({ formData, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        // `/partyconfmn/api/v1/recorecords/party/confirmation/map`,
        `/partyconfmn/api/v2/recorecords/party/confirmation/openitem/nonexcel-statement`,
        formData,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Confirmation Details
export const getAllPartyConfirmationDetails = createAsyncThunk(
  "getAllPartyConfirmationDetails",
  async ({ token }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/partyconfmn/api/v2/recorecords/confirmation/details/guest-user`,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAdditionalAttachments = createAsyncThunk(
  "removeAdditionalAttachments",
  async ({ statementId, token }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `/partyconfmn/api/v1/recorecords/party/confirmation/remove?statementId=${statementId}`,
        null,
        {
          baseURL: baseURL,
          headers: { Authorization: token },
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const confirmationSlice = createSlice({
  name: "confirmation",
  initialState: state,
  extraReducers: {
    [uploadLedger.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_LEDGER";
    },
    [uploadLedger.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_LEDGER";
      state.partyStatementData = action.payload;
      state.ledgerStatementId = action.payload?.statementId;
    },
    [uploadLedger.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_LEDGER";
      state.error = action.payload;
    },
    [validateData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_DATA";
    },
    [validateData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_DATA";
      // state.validateData = action.payload;
      state.partyStatementData = action.payload;
    },
    [validateData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_DATA";
      state.error = action.payload;
    },
    [validateDataOpenItem.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_DATA_OPEN_ITEM";
    },
    [validateDataOpenItem.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_DATA_OPEN_ITEM";
      // state.validateData = action.payload;
      state.openItemStatementData = action.payload;
    },
    [validateDataOpenItem.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_DATA_OPEN_ITEM";
      state.error = action.payload;
    },
    [getPartyData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "PARTY_DATA";
    },
    [getPartyData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "PARTY_DATA";
      state.partyData = action.payload;
    },
    [getPartyData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "PARTY_DATA";
      state.error = action.payload;
    },
    [attachDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ATTACH_DOCUMENT";
    },
    [attachDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ATTACH_DOCUMENT";
    },
    [attachDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ATTACH_DOCUMENT";
      state.error = action.payload;
    },
    [loadStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "LOAD_STATEMENT";
    },
    [loadStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "LOAD_STATEMENT";
    },
    [loadStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "LOAD_STATEMENT";
      state.error = action.payload;
    },
    [sendCode.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_CODE";
    },
    [sendCode.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_CODE";
      state.confirmerId = action.payload.confirmerId;
    },
    [sendCode.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_CODE";
      state.error = action.payload;
    },
    [verifyCode.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VERIFY_CODE";
    },
    [verifyCode.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VERIFY_CODE";
    },
    [verifyCode.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VERIFY_CODE";
      state.error = action.payload;
    },
    [removeUploadedFile.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REMOVE_PARTY_STATEMENT";
    },
    [removeUploadedFile.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REMOVE_PARTY_STATEMENT";
      state.removedData = action.payload;
      if (action.payload.requestType === "ledger") {
        state.show.ledgerFileSubmit = false;
      } else if (
        action.payload.requestType === "openItem" ||
        action.payload.requestType === "openitem"
      ) {
        state.show.openItemFileSubmit = false;
      }
    },
    [removeUploadedFile.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REMOVE_PARTY_STATEMENT";
      state.error = action.payload;
    },

    [actionRequest.pending]: (state, action) => {
      state.status = "loading";
      state.type = "ACTION_REQUEST";
    },
    [actionRequest.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "ACTION_REQUEST";
      state.actionrequest = action.payload;
    },
    [actionRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "ACTION_REQUEST";
      state.error = action.payload;
    },
    [getConfirmationSummary.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_CONFIRM_SUMMARY";
    },
    [getConfirmationSummary.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_CONFIRM_SUMMARY";
      state.show.summary = action.payload;
      state.show.mappingData = action.payload;
      // state.show.modal = true;
      if (action.payload.requestType === "ledger") {
        state.show.map = true;
      } else if (action.payload.requestType === "openItem") {
        state.show.mapOpen = true;
      }
      state.show.everythinglooksgood = action.payload?.hasErrors ? false : true;
    },
    [getConfirmationSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_CONFIRM_SUMMARY";
      state.error = action.payload;
    },
    [uploadOpenItem.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_OPENITEM";
    },
    [uploadOpenItem.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_OPENITEM";
      state.openItemStatementData = action.payload;
      state.openItemStatementId = action.payload?.statementId;
    },
    [uploadOpenItem.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_OPENITEM";
      state.error = action.payload;
    },
    [getBlankConfirmationData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_BLANK_CONFIRMATION_DATA";
    },
    [getBlankConfirmationData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_BLANK_CONFIRMATION_DATA";
    },
    [getBlankConfirmationData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_BLANK_CONFIRMATION_DATA";
      state.error = action.payload;
    },
    [putBlankConfirmation.pending]: (state, action) => {
      state.status = "loading";
      state.type = "PUT_BLANK_CONFIRMATION";
    },
    [putBlankConfirmation.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "PUT_BLANK_CONFIRMATION";
    },
    [putBlankConfirmation.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "PUT_BLANK_CONFIRMATION";
      state.error = action.payload;
    },
    //Party Send Verification Code
    [sendVerificationCode.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [sendVerificationCode.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [sendVerificationCode.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    //Party Verify OTP
    [verifyOTP.pending]: (state, action) => {
      state.status = "loading";
      state.type = "RAISE_QUERY";
    },
    [verifyOTP.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "RAISE_QUERY";
    },
    [verifyOTP.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "RAISE_QUERY";
      state.error = action.payload;
    },
    [putSkipMapping.pending]: (state, action) => {
      state.status = "loading";
      state.type = "PUT_SKIP_MAPPING";
    },
    [putSkipMapping.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "PUT_SKIP_MAPPING";
    },
    [putSkipMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "PUT_SKIP_MAPPING";
      state.error = action.payload;
    },
    [sendPartyUserData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEND_PARTY_USER_DATA";
    },
    [sendPartyUserData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEND_PARTY_USER_DATA";
    },
    [sendPartyUserData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEND_PARTY_USER_DATA";
      state.error = action.payload;
    },
    [uploadLedgerDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_LEDGER_DOCUMENT";
    },
    [uploadLedgerDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_LEDGER_DOCUMENT";
      state.partyStatementData = action.payload;
      state.ledgerStatementId = action.payload?.statementId;
    },
    [uploadLedgerDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_LEDGER_DOCUMENT";
      state.error = action.payload;
    },
    [uploadOpenItemDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_OPENITEM_DOCUMENT";
    },
    [uploadOpenItemDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_OPENITEM_DOCUMENT";
      state.partyStatementData = action.payload;
      state.openItemStatementId = action.payload?.statementId;
    },
    [uploadOpenItemDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_OPENITEM_DOCUMENT";
      state.error = action.payload;
    },
    [getAllPartyConfirmationDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
    },
    [getAllPartyConfirmationDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
      state.partyConfirmationDetails = action.payload;
    },
    [getAllPartyConfirmationDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_PARTY_CONFIRMATION_DETAILS";
      state.error = action.payload;
    },
    [removeAdditionalAttachments.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REMOVE_ADDITIONAL_ATTACHMENTS";
    },
    [removeAdditionalAttachments.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REMOVE_ADDITIONAL_ATTACHMENTS";
    },
    [removeAdditionalAttachments.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REMOVE_ADDITIONAL_ATTACHMENTS";
      state.error = action.payload;
    },
  },
  reducers: {
    reset: () => state,
    setModalData(state, action) {
      state.token = action.payload.token;
      //state.recoId = action.payload.recoId;
      //  state.partyId = action.payload.partyId;
    },
    setShow(state, action) {
      state.show = { ...state.show, ...action.payload };
    },
    setOpenItemFileName(state, action) {
      state.openItemFileName = action.payload;
    },
    setConfirmerId(state, action) {
      state.confirmerId = action.payload;
    },
    setOpenItemFile(state, action) {
      state.openItemFile = action.payload;
    },
    setLedgerFileName(state, action) {
      state.ledgerFileName = action.payload;
    },
    setLedgerFile(state, action) {
      state.ledgerFile = action.payload;
    },
    setIsLedgerFile(state, action) {
      state.isLedgerFile = action.payload;
    },
    setIsOpenItemFile(state, action) {
      state.isOpenItemFile = action.payload;
    },
    setLedgerOrOpenItemUpload(state, action) {
      state.ledgerOrOpenItemUpload = action.payload;
    },
    setConfirmationType(state, action) {
      state.confirmationType = action.payload;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setInfoBoxData(state, action) {
      state.infoboxdata = action.payload;
    },
    setCLAmount(state, action) {
      state.clAmount = action.payload;
    },
    setFirstRenderStr(state, action) {
      state.firstRenderStr = action.payload;
    },
    setPartyData(state, action) {
      state.partyData = action.payload;
    },
    setPartyRemark(state, action) {
      state.partyRemark = action.payload;
    },
    setCheckLedger(state, action) {
      state.checkLedger = action.payload;
    },
    setCheckOpenItem(state, action) {
      state.checkOpenItem = action.payload;
    },
    setConfirmationRecoId(state, action) {
      state.recoId = action.payload;
    },
    setLedgerFileType(state, action) {
      state.ledgetFileType = action.payload;
    },
    setOpenItemFileType(state, action) {
      state.openItemFileType = action.payload;
    },
    setOpenUploadModal(state, action) {
      state.openUploadModal = action.payload;
    },
    setAdditionalDocCount(state, action) {
      state.additionalDocCount = action.payload;
    },
    verifyClosingAmount(state, action) {
      state.checkClosingAmount = action.payload;
    },
    setDisplayAttchmentBox(state, action) {
      state.displayAttchmentBox = action.payload;
    },
    setConfirmationForm(state, action) {
      state.showConfirmationForm = action.payload;
    },
    setVerificationForm(state, action) {
      state.showVerificationForm = action.payload;
    },
  },
});

export const {
  setModalData,
  setShow,
  setLedgerFile,
  setConfirmerId,
  setLedgerFileName,
  setOpenItemFile,
  setOpenItemFileName,
  setIsLedgerFile,
  setIsOpenItemFile,
  setLedgerOrOpenItemUpload,
  reset,
  setConfirmationType,
  setCurrency,
  setInfoBoxData,
  setCLAmount,
  setFirstRenderStr,
  setPartyData,
  setPartyRemark,
  setCheckLedger,
  setCheckOpenItem,
  setConfirmationRecoId,
  setLedgerFileType,
  setOpenItemFileType,
  setOpenUploadModal,
  setAdditionalDocCount,
  verifyClosingAmount,
  setDisplayAttchmentBox,
  setConfirmationForm,
  setVerificationForm,
} = confirmationSlice.actions;

const { reducer } = confirmationSlice;

export default reducer;
