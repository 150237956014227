import React, { useState, useEffect } from "react";
import "assets/styles/selectpartytable.css";
import "assets/styles/statementrequest.css";
import "assets/styles/menu.css";
import "assets/styles/reviewdone.css";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  IconButton,
  Pagination,
  Stack,
  MenuItem,
  TextField,
  PaginationItem,
  Typography,
  Button,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Select,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import styled from "styled-components/macro";
import { visuallyHidden } from "@mui/utils";
import {
  setSort,
  getActionTrackerActionTakenStatusFilterList,
  getAttachmentAndCommentTableData,
  attachmentCommentfilterOfActionTakenStatus,
  setSelectedInfo,
  setSetOfComments,
} from "store/requeststatement/slice";
import { useDispatch, useSelector } from "react-redux";
import IsLoadingHOC from "components/loader/loader";
import makeStyles from "@mui/styles/makeStyles";
import { SortLabel, FilterIcon, RightIcon, CloseIcon } from "components/AllSvg";
import ActionTrackerCommentDrawer from "./ActionTrackerCommentDrawer";
import TiltleCrumbsFour from "./TitleCrumbsFour";
import SearchIcon from "@mui/icons-material/Search";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const cancelStyle = {
  width: "100px",
  height: "25px",
  fontSize: "12px",
  fontWeight: "700",
  background: "#FFFFFF",
  color: "#005724",
  border: "1px solid #FF9A01",
  boxSizing: "border-box",
  borderRadius: "5px",
  marginTop: "10px",
  padding: "5px",
};

const applybtnStyle = {
  width: "100px",
  height: "25px",
  background: "#005724",
  borderRadius: "5px",
  color: "#FFFFFF",
  marginTop: "10px",
  padding: "5px",
};

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
      marginLeft: "60px",
    },
  },
};

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const attachStyle = {
  height: "21px",
  borderRadius: "4px",
  background: "rgba(0, 87, 36, 1)",
  color: "#FFFFFF",
  padding: "10px",
};

const uploadedStyle = {
  padding: "10px",
  color: "rgba(0, 87, 36, 1)",
  height: "21px",
  borderRadius: "4px",
  border: "0.5px solid rgba(255, 154, 1, 1)",
  fontFamily: "SFProdisplay",
};

const actionBoxStyle = {
  // padding: "10px",
  backgroundColor: "#FA8F8F",
  height: "21px",
  borderRadius: "4px",
  border: "0.5px",
};

const headCells = [
  {
    id: "partyCode",
    disablePadding: false,
    label: "Party Code",
  },
  {
    id: "partyName",
    disablePadding: false,
    label: "Party Name",
  },
  {
    id: "particulars",
    disablePadding: false,
    label: "Particulars",
  },
  {
    id: "dateandtime",
    disablePadding: false,
    label: "Date & Time",
  },
  {
    id: "actionTakenStatus",
    disablePadding: false,
    label: "Action Taken Status",
  },
  {
    id: "document",
    disablePadding: false,
    label: "Document",
  },
];

function EnhancedTableHead(props) {
  const dispatch = useDispatch();
  const { sort } = useSelector((state) => state.trackreconciliation);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={
              sort.id === headCell.id
                ? sort.direction === "ascending"
                  ? "asc"
                  : "desc"
                : "asc"
            }
          >
            <TableSortLabel
              IconComponent={() => (
                <Box sx={{ marginTop: "15px" }}>
                  <SortLabel />
                </Box>
              )}
              active={headCell.id === sort.id}
              direction={
                sort.id === headCell.id
                  ? sort.direction === "ascending"
                    ? "asc"
                    : "desc"
                  : "asc"
              }
              onClick={() =>
                dispatch(
                  setSort({
                    id: headCell.id,
                    direction:
                      sort.direction === "ascending"
                        ? "descending"
                        : "ascending",
                  })
                )
              }
            >
              {headCell.label}
              {sort.id === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.direction === "ascending"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// main table
function AttachmentsAndComments({ setLoading }) {
  const dispatch = useDispatch();

  const {
    selected,
    attchementCommentTableData,
    sort,
    recoId,
    actionTakenStatusforViewActionTracker,
  } = useSelector((state) => state.trackreconciliation);

  let sortId = sort.id;
  let sortDir = sort.direction;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowData, setRowData] = useState([]);
  const [openCommentDrawer, setopenCommentDrawer] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [drawerRefresh, setdrawerRefresh] = useState(false);
  const [openSelectBox, setopenSelectBox] = useState(false); //open action taken status dropdown box
  const [search, setSearch] = useState("");
  const [searchString, setSearchString] = useState("");

  const [
    actionTrackerActionTakenStatusFilterList,
    setActionTrackerActionTakenStatusFilterList,
  ] = useState([]); //for action Taken status dropdown list

  const [
    gettingUpdatedActionTakenStatusList,
    setgettingUpdatedActionTakenStatusList,
  ] = useState([]); //for action taken status dropdown change-selected values

  //render function for action taken status
  const getSelectActionTakenStatusValues = (selected) => {
    return actionTrackerActionTakenStatusFilterList
      ?.filter((name) => selected.includes(name.actionStatusCode))
      ?.map((record) => record.actionStatusCode ?? record.actionStatusValue)
      ?.join(", ");
  };

  //For All dropdown
  useEffect(() => {
    dispatch(getActionTrackerActionTakenStatusFilterList()).then((res) => {
      setActionTrackerActionTakenStatusFilterList(res?.payload?.data);
    });
    return () => {
      setActionTrackerActionTakenStatusFilterList([]);
    };
  }, [dispatch]);

  //table data of attachment and comments
  useEffect(() => {
    dispatch(
      getAttachmentAndCommentTableData({
        recoId,
        pageNo: page - 1,
        pageSize: rowsPerPage,
        sortBy: sortId,
        sortDirection: sortDir,
        searchString: searchString,
        actionStatus: actionTakenStatusforViewActionTracker,
      })
    ).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchString, refresh, page, rowsPerPage, sortId]);

  useEffect(() => {
    let obj = (attchementCommentTableData?.attactmentSummary ?? []).map(
      (val) => {
        return {
          recoId: val?.recoId,
          partyId: val?.partyId,
          partyCode: val?.partyCode,
          partyName: val?.partyName,
          updateDateTime: val?.updateDateTime,
          actionStatus: val?.actionStatus,
          summaryName: val?.summaryName,
          summaryCode: val?.summaryCode,
          documentAvailable: val?.documentAvailable,
        };
      }
    );
    setRowData(obj);
  }, [attchementCommentTableData]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const useStyles = makeStyles(() => ({
    selected: {
      color: "#FFFFFF",
      background: "#005724",
    },
    root: {
      color: "#005724",
      background: "#FFFFFF",
      border: "1px solid #005724",
      borderRadius: "4px",
    },
  }));

  const classes = useStyles();

  //handle Attachment button click
  const handleAttachFileClick = (row) => {
    dispatch(setSetOfComments([]));
    dispatch(setSelectedInfo(row));
    // setActionTrackerSummaryCode(row?.summaryCode);
    setdrawerRefresh(!drawerRefresh);
    setopenCommentDrawer(true);
    // dispatch(getAllComments({ recoId, partyId: partyInfo.id })).then(() => {
    //   setopenCommentDrawer(true);
    //   setAnchorEl(null);
    // });
  };

  //action taken status select all
  const isAllActionStatusSelected =
    actionTrackerActionTakenStatusFilterList?.length > 0 &&
    gettingUpdatedActionTakenStatusList?.length ===
      actionTrackerActionTakenStatusFilterList?.length;

  //get value of status from dropdown
  const handleActionStatusChange = (event) => {
    if (
      event.target.value.includes("all") &&
      gettingUpdatedActionTakenStatusList.length !==
        actionTrackerActionTakenStatusFilterList.length
    ) {
      setgettingUpdatedActionTakenStatusList(
        actionTrackerActionTakenStatusFilterList.map(
          // (name) => name.actionStatusValue
          (name) => name
        )
      );
    } else if (event.target.value.includes("all")) {
      setgettingUpdatedActionTakenStatusList([]);
    } else {
      setgettingUpdatedActionTakenStatusList(event.target.value);
    }
  };

  // handle action taken filter status Apply Button Click
  const handleActionTakensStatusSelectClick = () => {
    gettingUpdatedActionTakenStatusList.length !== 0
      ? setgettingUpdatedActionTakenStatusList([])
      : setgettingUpdatedActionTakenStatusList(
          gettingUpdatedActionTakenStatusList
        );

    dispatch(
      attachmentCommentfilterOfActionTakenStatus({
        recoId: recoId,
        pageNo: page - 1,
        pageSize: rowsPerPage,
        sortBy: sortId,
        sortDirection: sortDir,
        actionStatus: gettingUpdatedActionTakenStatusList,
      })
    ).then((res) => {
      // setRefresh(!refresh);
      setopenSelectBox(false);
    });
  };

  return (
    <>
      <TiltleCrumbsFour />

      <Box
        style={{ position: "relative" }}
        sx={{
          backgroundColor: "#ffffff",
          padding: "12px",
          borderRadius: "8px",
          boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
        }}
      >
        {/* Header and search box */}
        <Box
          bgcolor={"#F3F3F3"}
          padding="10px"
          borderRadius={"7.5px"}
          marginBottom={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Heading */}
          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"SFProdisplay"}
          >
            Attachments & Comments
          </Typography>

          {/* Search Box */}
          <Stack sx={{ mx: "auto" }} direction={"row"} gap={3}>
            {rowData.length === 0 && (
              <Typography fontFamily={"fontsfree"} fontSize={"14px"}>
                No records found.
              </Typography>
            )}
            {rowData.length === 0 && searchString !== "" && (
              <Typography fontFamily={"fontsfree"} fontSize={"14px"}>
                Press <CloseIcon fontSize="14px" /> to clear.
              </Typography>
            )}
          </Stack>
          <Stack
            height={"35px"}
            borderRadius={"18px"}
            border={"1px solid #BBBBBB"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pl={"20px"}
            direction={"row"}
            bgcolor={"#FFF"}
            mr={2}
          >
            <input
              className="search-input"
              placeholder="Type and press enter to Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  setSearchString("");
                  setRowsPerPage(10);
                  setPage(1);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchString(e.target.value);
                  setRowsPerPage(10);
                  setPage(1);
                }
              }}
              style={{ width: "220px" }}
            ></input>
            {searchString !== "" ? (
              <CustomTooltip title="Clear" arrow placement="top">
                <IconButton
                  onClick={() => {
                    setSearch("");
                    setSearchString("");
                    setRowsPerPage(10);
                    setPage(1);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </CustomTooltip>
            ) : (
              <div style={{ width: "27px" }}></div>
            )}
            <CustomTooltip title="Search" arrow placement="top">
              <Stack
                height={"35px"}
                width={"35px"}
                borderRadius={"50%"}
                bgcolor={search !== "" ? "#005724" : "#ececec"}
                border={"1px solid #BBBBBB"}
                sx={{ cursor: "pointer" }}
                alignItems={"center"}
                justifyContent={"center"}
                mr={"-2px"}
                onClick={() => {
                  setSearchString(search);
                  setPage(1);
                }}
              >
                <SearchIcon
                  sx={{ color: search === "" ? "#005724" : "#ffffff" }}
                />
              </Stack>
            </CustomTooltip>
          </Stack>
        </Box>

        {/* Action Taken Status} */}
        <Box position={"relative"} mb={2}>
          {/* {gettingUpdatedActionTakenStatusList?.length === 0 && ( */}
          <Typography
            style={{
              position: "absolute",
              left: "15px",
              top: "6px",
              fontFamily: "fontsfree",
            }}
          >
            Action Taken Status
          </Typography>
          {/* )} */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            open={openSelectBox}
            onOpen={() => setopenSelectBox(true)}
            onClose={() => setopenSelectBox(false)}
            className="actionfilter_box"
            value={gettingUpdatedActionTakenStatusList}
            displayEmpty
            defaultValue=""
            onChange={handleActionStatusChange}
            IconComponent={FilterIcon}
            // renderValue={(selected) => selected.join(", ")}
            renderValue={getSelectActionTakenStatusValues}
            MenuProps={MenuProps}
            size="small"
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllActionStatusSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllActionStatusSelected}
                  indeterminate={
                    selected.length > 0 &&
                    selected.length <
                      actionTrackerActionTakenStatusFilterList.length
                  }
                  color="success"
                  size="small"
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
            {actionTrackerActionTakenStatusFilterList?.map((option, index) => (
              <MenuItem key={index} value={option}>
                <Checkbox
                  checked={
                    gettingUpdatedActionTakenStatusList.indexOf(option) > -1
                    // gettingUpdatedActionTakenStatusList.indexOf(
                    //   option.actionStatusCode
                    // ) > -1
                  }
                  color="success"
                  size="small"
                />
                <ListItemText primary={option.actionStatusValue} />
              </MenuItem>
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                gap: 5,
              }}
            >
              <Button
                variant="text"
                onClick={() => setopenSelectBox(false)}
                style={cancelStyle}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                onClick={handleActionTakensStatusSelectClick}
                style={applybtnStyle}
              >
                Select
              </Button>
            </Box>
          </Select>
        </Box>

        <Box bgcolor={"#F3F3F3"} padding="16px" borderRadius={"7.5px"}>
          <Paper
            style={{
              border: "0.5px solid #BBBBBB",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <TableContainer sx={{ maxHeight: "70vh" }}>
              <Table
                stickyHeader
                aria-labelledby="tableTitle"
                size={"small"}
                sx={{ bgcolor: "#FFF", borderRadius: "7.5px" }}
              >
                <EnhancedTableHead rowCount={rowData.length} />
                <TableBody>
                  {rowData?.map((row, index) => {
                    // const isItemSelected = isSelected(row?.partyId);
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow key={index}>
                        <TableCell align="center">{row?.partyCode}</TableCell>
                        <TableCell align="center">{row?.partyName}</TableCell>
                        <TableCell align="center">{row?.summaryName}</TableCell>
                        <TableCell align="center">
                          {row?.updateDateTime}
                        </TableCell>
                        <TableCell align="center">
                          <Box style={actionBoxStyle}> {row?.actionStatus}</Box>
                        </TableCell>
                        <TableCell align="center">
                          {row?.documentAvailable === true ? (
                            <Box>
                              <Button
                                style={uploadedStyle}
                                variant="outlined"
                                startIcon={<RightIcon />}
                                onClick={() => handleAttachFileClick(row)}
                              >
                                Uploaded
                              </Button>
                            </Box>
                          ) : (
                            <Box>
                              <Button
                                variant="outlined"
                                style={attachStyle}
                                onClick={() => handleAttachFileClick(row)}
                              >
                                Attach File
                              </Button>
                            </Box>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {/* toolbar for pagination and row per page */}
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
            style={{ marginLeft: "-20px" }}
          >
            <TextField
              select
              size="small"
              className="page-per-row"
              defaultValue={10}
              onChange={handleChangeRowsPerPage}
              sx={{
                ".MuiSelect-select": {
                  fontFamily: "SFProdisplay",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#005724",
                },
                ".MuiSvgIcon-root-ffqUiY": {
                  color: "#005724",
                },
              }}
            >
              <MenuItem className="menu-items" value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={25}>25 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
              <MenuItem value={100}>100 per page</MenuItem>
            </TextField>

            <Stack spacing={2} className="pagination-box">
              <Pagination
                page={page}
                shape="rounded"
                component="div"
                defaultPage={1}
                count={attchementCommentTableData?.totalPages ?? 1}
                onChange={handleChangePage}
                renderItem={(item) => (
                  <PaginationItem
                    variant="outlined"
                    {...item}
                    classes={{
                      root: classes.root,
                      selected: classes.selected,
                    }}
                  />
                )}
              />
            </Stack>
          </Toolbar>
        </Box>
        <ActionTrackerCommentDrawer
          openCommentDrawer={openCommentDrawer}
          setopenCommentDrawer={setopenCommentDrawer}
          refresh={refresh}
          setRefresh={setRefresh}
          drawerRefresh={drawerRefresh}
        />
      </Box>
    </>
  );
}

export default IsLoadingHOC(AttachmentsAndComments);
