import React, { useEffect, useState } from "react";
import NoStatementCard from "./NoStatementCard";
import SftpTable from "./SftpTable";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { RefreshIcon } from "components/AllSvg";
import { useDispatch, useSelector } from "react-redux";
import { sftpFileList } from "store/sftp/slice";
import IsLoadingHOC from "components/loader/loader";

const MainCardSftp = ({ setLoading }) => {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  const { companyId } = useSelector((state) => state.masterData);
  const { company_code } = useSelector((state) => state.sftp);

  useEffect(() => {
    setLoading(true);
    dispatch(
      sftpFileList({ id: companyId, pageSize: 2, page: 1, company_code })
    ).then((res) => {
      setTableData(res?.payload?.statements);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box
        bgcolor={"#FFF"}
        padding="24px"
        borderRadius="7.5px"
        marginTop={"-30px"}
      >
        <Grid container sx={{ marginBottom: "10px" }}>
          {/* Statement Request Label */}
          <Typography fontFamily={"fontsfree"} fontSize="20px">
            Statement Transformation
          </Typography>
          <IconButton sx={{ ml: "auto" }}>
            <RefreshIcon />
          </IconButton>
        </Grid>
        {tableData?.length === 0 ? <NoStatementCard /> : <SftpTable />}
      </Box>
    </>
  );
};

export default IsLoadingHOC(MainCardSftp);
